<template lang="pug">
  b-card.manager-categories-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.category.one') }}
    b-form.form-wc.form-categories-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            wc-forms-translations(
              v-for='(locale, key) in $wc.conf.base.locales'
              :key='key'
              :locale='locale'
              :lc='key')
              b-collapse(
                :id='`tl-${key}`'
                :ref='`${key}Tl`'
                :visible="action === 'create' ? true : formShowSection(key)"
                role='tabpanel')
                b-card-body
                  b-form-group(
                    :id='`form-category-name_${key}`'
                    :label="$t('activerecord.attributes.shared.name') + ' ' + $t(`locales.${key}`)"
                    label-for='`category-name_${key}`'
                    label-cols-md='2'
                    label-align-md='right')
                    b-form-input.shadow-sm.is-required(
                      :id='`category-name_${key}`'
                      name='`name_${key}`'
                      type='text'
                      autocomplete='name'
                      :placeholder="$t('category.placeholders.name')"
                      :state='formValidationState($v.form.data.attributes[`name_${key}`])'
                      @focus.native='formChange'
                      v-model='$v.form.data.attributes[`name_${key}`].$model'
                      aria-describedby='`category-name_${key}-feedback`'
                      trim)
                    wc-forms-if(
                      :id='`category-name_${key}-feedback`'
                      extra-class='ml-feedback'
                      :attribute='$v.form.data.attributes[`name_${key}`]'
                      :remote="formRemoteInvalidFeedback(`name_${key}`)"
                      :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-category-parent.mt-4(
              :label="$t('activerecord.attributes.category.parent')"
              label-for='category-parent_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#category-parent_id.shadow-sm.custom-select(
                name='parent'
                v-model='categoryParentIdSelected'
                :options='form.selects.categoryParentIdOptions'
                :class='formValidationClass($v.form.data.attributes.parent_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('category.placeholders.parent')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.categoryParentIdLoading'
                @search-change='searchParent'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}
              wc-forms-if#category-parent_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.parent_id'
                :remote="formRemoteInvalidFeedback('parent')"
                :validators='{}')

            b-form-group#form-category-available(
              :label="$t('activerecord.attributes.shared.available')"
              label-for='category-available'
              label-cols-md='2'
              label-align-md='right')
              b-form-checkbox-group#category-available(
                name='available'
                v-model='categoryAvailableSelected'
                size='md'
                button-variant='primary'
                aria-describedby='category-available-feedback'
                buttons)
                b-form-checkbox.shadow-none(
                  v-for='(option, index) in categoryAvailableOptions'
                  :key='index'
                  :value='option.value')
                  | {{ option.text }}
              wc-forms-if#category-available-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.available'
                :remote="formRemoteInvalidFeedback('available')"
                :validators='{ required: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-category-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsTranslations from '@components/shared/forms/WcFormsTranslations'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiCategories from '@services/api/manager/globals/categories'
import { Form, mapSelects } from '@common/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-categories-form',
  mixins: [Form],
  components: {
    WcFormsTranslations,
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'categoryParentId',
        default: 'relationships.parent.data.id',
        attribute: 'attributes.parent_id',
        included: { type: 'category', key: 'id', value: 'attributes.name' },
      },
      {
        name: 'categoryAvailable',
        default: 'attributes.available',
        attribute: 'attributes.available',
        collection: {
          data: 'available.data',
          key: 'id',
          value: 'attributes.name',
          options: { key_label: 'text', raw_ids: true },
        },
      },
    ]),
    categoryUpdateQuery() {
      return this.action === 'update' ? { id: this.category_id } : {}
    },
    apiParams() {
      return {
        get: [{ id: this.category_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.category_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        categoryParentIdSearch: [
          {},
          {
            ...this.categoryUpdateQuery,
            term: this.form.selects.categoryParentIdTerm,
          },
        ],
      }
    },
  },
  methods: {
    searchParent(term, id) {
      this.formMultiselectSearch(term, id, 'searchParent')
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            parent_id: {
              remote: () => this.formRemoteValid('parent'),
            },
            available: {
              required,
              remote: () => this.formRemoteValid('available'),
            },
          },
        },
      },
    }
    for (let locale of this.$wc.conf.base.available_locales) {
      validations.form.data.attributes[`name_${locale}`] = {
        required,
        maxLength: maxLength(this.$wc.conf.limits.max_name),
        remote: () => this.formRemoteValid(`name_${locale}`),
      }
      validations.form.data.attributes[`${locale}Tl`] = [`form.data.attributes.name_${locale}`]
    }
    return validations
  },
  data() {
    return {
      apiBase: apiCategories,
      apiModel: 'category',
      category_id: this.$route.params.category_id,
      asyncDataSelects: ['categoryParentId'],
    }
  },
}
</script>
